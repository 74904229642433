import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenService } from '../shareds/services/authen.service';
import { AlertService } from '../shareds/services/alert.service';
import { AppURL } from '../app.url';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private authen: AuthenService,
    private router: Router,
    private alert: AlertService
  ) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authen.getAuthenticated() && this.authen.isNotExpireToken()) {
      return true;
    } else {
      this.authen.clearLoginVariable();
      this.alert.notify("คุณยังไม่ได้ยืนยันตัวตน หรือมีบางอย่างผิดพลาด", "danger");
      this.router.navigate(['/', AppURL.Login, { returnURL: state.url }]);
      return false;
    }
  }

}
