<app-auth-navbar></app-auth-navbar>
<app-auth-sidebar style="min-height: 100vh;"></app-auth-sidebar>
<div class="content-wrapper">

    <!-- Main content -->
    <div class="content">
        <ng-content></ng-content>
    </div>
    <!-- /.content -->
</div>
<!-- /.content-wrapper -->

<!-- Control Sidebar -->
<aside class="control-sidebar control-sidebar-dark head-font-5">
    <!-- Control sidebar content goes here -->
    <div class="p-3">
        <h5>รายการแจ้งเตือน</h5>
        <p>ไม่มีรายการแจ้งเตือน</p>
    </div>
</aside>
<!-- /.control-sidebar -->

<app-auth-footer></app-auth-footer>