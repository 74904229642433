import { Injectable } from "@angular/core";
import * as jwt_decode from "jwt-decode";
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class AuthenService {
    private accessKey = "fgdfgdhgfghj";
    private ictAccessKey = "ujlSAPLASLDjdydufwgjtgjtgjnxhhue";
    private phpsAccessKey = "hdsfslkow;eprlp3rplasddlammfweiiqakdnkdiq";
    private userPhpAccessKey = "hwqeoqk";
    private immAccessKey = "img_access_key";


    setAuthenticated(accessToken: string): void {
        localStorage.setItem(this.accessKey, accessToken);
    }
    setAuthenticatedIct(accessToken: string): void {
        localStorage.setItem(this.ictAccessKey, accessToken);
    }
    setAuthenticatePhps(accessToken: string): void {
        localStorage.setItem(this.phpsAccessKey, accessToken);
    }
    setImmData(data: string): void {
        localStorage.setItem(this.immAccessKey, JSON.stringify(data));
    }

    getAuthenticated(): string {
        return localStorage.getItem(this.accessKey);
    }
    getAuthenticatedIct(): string {
        return localStorage.getItem(this.ictAccessKey);
    }
    getAuthenticatedPhps(): string {
        return localStorage.getItem(this.phpsAccessKey);
    }
    getImmData(): ImmigrationData {
        return <ImmigrationData>JSON.parse(localStorage.getItem(this.immAccessKey));
    }
    clearImmData() {
        localStorage.removeItem(this.immAccessKey);
    }

    clearAuthenticate(): void {
        localStorage.removeItem(this.accessKey);
    }
    clearAuthenticateIct(): void {
        localStorage.removeItem(this.ictAccessKey);
    }

    setAppVersion(version: string) {
        localStorage.setItem('app-version', version);
    }
    getAppVersion() {
        return localStorage.getItem('app-version');
    }
    getRole() {
        try {
            let role: any[] = jwt_decode(this.getAuthenticated()).roles;
            return role;
        } catch (error) {
            return [];
        }
    }

    getDecodeJWT() {
        try {
            let decoced = jwt_decode(this.getAuthenticated());
            return decoced;
        } catch (error) {
            return null;
        }

    }
    getDecodeJWTtoken(token) {
        try {
            let decoced = jwt_decode(token);
            return decoced;
        } catch (error) {
            return null;
        }

    }

    isAuthorized(allowedRoles: string[]): boolean {
        // check if the list of allowed roles is empty, if empty, authorize the user to access the page
        if (allowedRoles == null || allowedRoles.length === 0) {
            return true;
        }

        // check if the user roles is in the list of allowed roles, return true if allowed and false if not allowed
        //console.log(this.getRole(), " <- memberRoles");
        //console.log(allowedRoles, ' <- pageRoles');


        let found = false;
        let user_role = this.getRole();
        for (let i = 0; i < allowedRoles.length; i++) {
            for (let j = 0; j < user_role.length; j++) {
                // console.log(allowedRoles[i] ,"==", user_role[j]);
                if (allowedRoles[i] == user_role[j]) {
                    found = true;
                    break;
                }
            }
        }
        // found = allowedRoles.some(r => this.getRole().includes(r));
        //console.log(found, "<- found");

        return found;
    }

    isNotExpireToken() {
        var expire = true;
        try {
            let token = jwt_decode(this.getAuthenticated());
            //console.log(token, "<- token");
            let now = moment().unix();
            let expire_date = token.exp;
            if (now > expire_date) {
                expire = false;
            }
            //console.log(now,expire_date,"<- time");
        } catch (error) {
            return false;
        }
        return expire;
    }

    clearLoginVariable() {
        this.clearAuthenticate();
        localStorage.removeItem("cid");
        localStorage.removeItem("name");
        localStorage.removeItem("last_name");
        localStorage.removeItem("email");
        localStorage.removeItem("job_position");
        localStorage.removeItem("token_for_register");
    }

    clearPhpsAuthen() {
        localStorage.removeItem(this.phpsAccessKey);
        localStorage.removeItem(this.userPhpAccessKey);
    }
    setUserPhps(str: string): void {
        localStorage.setItem(this.userPhpAccessKey, str);
    }
    getUserPhps() {
        return JSON.parse(localStorage.getItem(this.userPhpAccessKey));
    }
    getUserFromToken() {
        try {
            // {
            //     "sub": "xxxxxx",
            //     "roles": [
            //       "IMMIGRATION"
            //     ],
            //     "firstName": "xxx",
            //     "lastName": "xxx",
            //     "email": "xxx@gmail.com",
            //     "immId": "1",
            //     "iat": 1585878050,
            //     "exp": 1585933199
            //   }
            let token: TokenData = <TokenData>jwt_decode(this.getAuthenticated());
            return token;
            //console.log(now,expire_date,"<- time");
        } catch (error) {
            return {
                "sub": "",
                "roles": [],
                "firstName": "",
                "lastName": "",
                "email": "",
                "immId": "",
                "iat": "",
                "exp": ""
            };
        }
    }
}

export interface TokenData {
    sub: string;
    roles: string[];
    firstName: string;
    lastName: string;
    email: string,
    immId: string;
    iat: string;
    exp: string;
}
export interface ImmigrationData {
    department: string;
    departmentName: string;
    id: string;
    name: string;
    province: string;
    provinceName: string;
    type: string;
    typeName: string;
}
