<section>
  <!-- <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large">
    <div class="text-center"  style="position: relative; top:-35px;" >
      <img src='assets/images/moph-logo.gif' width="70"/>
      <p style="font-size: 20px; color: white" class="head-font-2">กรุณารอซักครู่...</p>
    </div>
  </ngx-spinner> -->
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#fff" type="square-jelly-box" [fullScreen]="true"
    template="<img src='assets/images/moph-loading.gif' width='110'/>">
    <p style="color: white" class="head-font-2">กรุณารอซักครู่...</p>
  </ngx-spinner>
  <router-outlet></router-outlet>
</section>