import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AuthenService } from './authen.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private http: HttpService,
    private authen: AuthenService
  ) {

  }

  async getUserProfile() {
    return await this.http.requestGet('api/user/me', this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }

  async updateProfile(data) {
    return await this.http.requestPut('api/user/personal/v1', data, this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async getAllUserLimit(start: number, limit: number) {
    return await this.http.requestGet('api/users/v1/all/' + start + "/" + limit, this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async getSearchUserLimit(keyword: string, start: number, limit: number) {
    return await this.http.requestGet('api/users/v1/search/' + keyword + "/" + start + "/" + limit, this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async updateUserStatus(cid: string, status: string) {
    return await this.http.requestPost('api/users/v1/status/' + cid + "/" + status, "", this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async getAllUserByStatus(status: string) {
    return await this.http.requestPost('api/users/v1/status/' + status, "", this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async getAllUserHasImmigrationByStatusLimit(status: string, start: number, limit: number) {
    return await this.http.requestGet('api/users/v1/all_by_status/' + status + "/" + start + "/" + limit, this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async getAllUserByKeywordStatusLimit(keyword: string, status: string, start: number, limit: number) {
    return await this.http.requestGet('api/users/v1/all_by_keyword_status/' + keyword + "/" + status + "/" + start + "/" + limit, this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async getAllUserHasImmigrationRoleLimit(start: number, limit: number) {
    return await this.http.requestGet('api/users/v1/role/immigration/only/all/' + start + "/" + limit, this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async searchAllUserHasImmigrationRoleLimit(keyword: string, status: string, start: number, limit: number) {
    return await this.http.requestGet('api/users/v1/role/immigration/only/keyword/' + keyword + '/status/' + status + '/start/' + start + '/limit/' + limit, this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async getAllUserHasImmigrationAdminLV1RoleLimit(start: number, limit: number) {
    return await this.http.requestGet('api/users/v1/role/immigration/with-or-without/admin-lv1/all/' + start + "/" + limit, this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async searchUserHasImmigrationAdminLV1RoleLimit(keyword: string, start: number, limit: number) {
    return await this.http.requestGet('api/users/v1/role/immigration/with-or-without/admin-lv1/search/' + keyword + "/" + start + "/" + limit, this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async searchUserNotAdminLV3Limit(keyword: string, start: number, limit: number) {
    return await this.http.requestGet('api/users/v1/role/all_not_admin_lv3/search/' + keyword + "/" + start + "/" + limit, this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async getAllUserNotAdminLV3Limit(start: number, limit: number) {
    return await this.http.requestGet('api/users/v1/role/all_not_admin_lv3/all/' + start + "/" + limit, this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async searchUserHasImmigrationRoleLimit(keyword: string, start: number, limit: number) {
    return await this.http.requestGet('api/users/v1/role/immigration/only/keyword/' + keyword + '/start/' + start + '/limit/' + limit, this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async getAllUserByCid(cid: string) {
    return await this.http.requestPost('api/user/v1/' + cid, "", this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async getUserZonecode() {
    return await this.http.requestGet('api/user/v1/current-user', this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async getAllUserForApprove(param: string) {
    return await this.http.requestGet('api/users/v1' + param, this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async addLevel1RoleToUser(cid: string) {
    return await this.http.requestPost('api/user-roles/v1/ADMIN_LV1/' + cid, "", this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async deleteLevel1RoleToUser(cid: string) {
    return await this.http.requestDelete('api/user-roles/v1/ADMIN_LV1/' + cid, this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async addLevel2RoleToUser(cid: string) {
    return await this.http.requestPost('api/user-roles/v1/ADMIN_LV2/' + cid, "", this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async deleteLevel2RoleToUser(cid: string) {
    return await this.http.requestDelete('api/user-roles/v1/ADMIN_LV2/' + cid, this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async getImmigrationByID(id: string) {
    return await this.http.requestGet('public/api/immigration/v1/' + id)
      .toPromise() as Promise<any>;
  }
  async getUserForEnable(keyword: string, status: string, start: number, limit: number) {
    let param = 'start=' + start + '&limit=' + limit;
    if (keyword != "" && keyword != undefined && keyword != null) {
      param += "&keyword=" + keyword;
    }
    if (status != "" && status != undefined && status != null) {
      param += "&status=" + status;
    } else {
      param += "&status=all";
    }
    return await this.http.requestGet('api/users/v1/under_lv1?' + param, this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
  async getUserForSetAdminLV1(keyword: string, status: string, start: number, limit: number) {
    let param = 'start=' + start + '&limit=' + limit;
    if (keyword != "" && keyword != undefined && keyword != null) {
      param += "&keyword=" + keyword;
    }
    if (status != "" && status != undefined && status != null) {
      param += "&status=" + status;
    } else {
      param += "&status=all";
    }
    return await this.http.requestGet('api/users/v1/under_lv2?' + param, this.authen.getAuthenticated())
      .toPromise() as Promise<any>;
  }
}
