import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthNavbarComponent } from './components/auth-navbar/auth-navbar.component';
import { AuthSidebarComponent } from './components/auth-sidebar/auth-sidebar.component';
import { AuthContentComponent } from './components/auth-content/auth-content.component';
import { AuthFooterComponent } from './components/auth-footer/auth-footer.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AlertService } from './services/alert.service';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ValidatorsService } from './services/validators.service';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { thLocale } from 'ngx-bootstrap/locale';
import { DatePipe } from '@angular/common';
import { LSelect2Module } from 'ngx-select2';
import { ShareService } from './services/shared.service';
import { NgxSelectModule } from 'ngx-select-ex';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FileSaverModule } from 'ngx-filesaver';
import { ChartjsModule } from '@ctrl/ngx-chartjs';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

defineLocale('th', thLocale);


export let options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
  declarations: [
    AuthNavbarComponent,
    AuthSidebarComponent,
    AuthContentComponent,
    AuthFooterComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot(),
    HttpClientModule,
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(options),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    LSelect2Module,
    NgxSelectModule,
    TypeaheadModule.forRoot(),
    FileSaverModule,
    ChartjsModule,
    NgxJsonViewerModule,
  ],
  exports: [
    AuthSidebarComponent,
    AuthNavbarComponent,
    AuthFooterComponent,
    AuthContentComponent,
    NgxPermissionsModule,
    ToastrModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ModalModule,
    NgxMaskModule,
    PaginationModule,
    BsDatepickerModule,
    LSelect2Module,
    NgxSelectModule,
    TypeaheadModule,
    FileSaverModule,
    TimepickerModule,
    ChartjsModule,
    NgxJsonViewerModule,
  ],
  providers: [
    AlertService,
    ValidatorsService,
    DatePipe,
    ShareService,
  ]
})
export class SharedsModule { }