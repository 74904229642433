export const PageRoles = {
    Profile: ['MEMBER'],
    AddImmigrationCheck: ['IMMIGRATION'],
    AddFormT8: ['IMMIGRATION'],
    EditFormT8: ['IMMIGRATION'],
    EnableUser: ['ADMIN_LV1'],
    ChangeUserRole: ['ADMIN_LV3'],
    SetAdminLV1: ['ADMIN_LV2'],
    ImmigrationExport: ['IMMIGRATION'],
    AdminExport: ['ADMIN_LV1'],
    Starter: ['MEMBER'],
    AdminImportMFA: ["ADMIN_LV3"],
    AddFlight: ["IMMIGRATION"],
    EditFlight: ["ADMIN_LV3"],
    AddQuarantinePlace: ["ADMIN_LV1"],
    GrantUserPermission:["ADMIN_LV1"],
    ProvincialExport:["PROVINCIAL_OFFICE"],
    MophZoneExport:["MOPH_ZONE_01","MOPH_ZONE_02","MOPH_ZONE_03","MOPH_ZONE_04","MOPH_ZONE_05","MOPH_ZONE_06","MOPH_ZONE_07","MOPH_ZONE_08","MOPH_ZONE_09","MOPH_ZONE_10","MOPH_ZONE_11","MOPH_ZONE_12","MOPH_ZONE_13"],
    OdpcZoneExport:["ODPC_ZONE"],
    Statistic:["MEMBER","ADMIN_LV1"],
}