import { Routes, RouterModule } from '@angular/router';
import { AppURL } from './app.url';
import { AuthenticationGuard } from './guards/authentication.guard';

const RouteLists: Routes = [
    {
        path: '',
        redirectTo: AppURL.Pages,
        pathMatch: 'full'
    },
    {
        path: AppURL.Authen,
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
        canActivate: [AuthenticationGuard]
    },
    {
        path: AppURL.Pages,
        loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
    },
    {
        path: '**',
        redirectTo: AppURL.Pages,
        pathMatch: 'full'
    }
]

export const AppRouting = RouterModule.forRoot(RouteLists);