import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class HttpService {
    constructor(
        private http: HttpClient,
    ) {

    }

    private address: string = environment.api_url;
    // ส่งข้อมูลแบบ Get method
    requestGet(url: string, accessToken?: string) {
        return this.http
            .get(`${this.address}/${url}`, {
                headers: this.appendHeaders(accessToken)
            })
            .pipe(catchError(err => this.handelError(err)));
    }
    requestGetNotJSON(url: string, accessToken?: string) {
        return this.http
            .get(`${this.address}/${url}`, {
                headers: this.appendHeaders(accessToken),
                responseType: 'text',
            })
            .pipe(catchError(err => this.handelError(err)));
    }
    requestGetOutSide(url: string, accessToken?: string) {
        return this.http
            .get(`${url}`, {
                headers: {
                    "jwt-token": accessToken,
                    "Authorization": `Bearer ${accessToken}`
                }
            })
            .pipe(catchError(err => this.handelError(err)));
    }
    requestGetOutSideNoheader(url: string, accessToken?: string) {
        return this.http
            .get(`${url}`, {})
            .pipe(catchError(err => this.handelError(err)));
    }
    requestGetFullUrl(url: string) {
        return this.http
            .get(`${url}`)
            .pipe(catchError(err => this.handelError(err)));
    }

    requestGetFile(url: string, accessToken?: string) {
        return this.http
            .get(`${this.address}/${url}`, {
                headers: this.appendHeaders(accessToken),
                observe: 'response',
                responseType: 'blob'
            })
            .pipe(catchError(err => this.handelError(err)));
    }

    // ส่งข้อมูลแบบ Post method
    requestPost(url: string, body: any, accessToken?: string) {
        return this.http
            .post(`${this.address}/${url}`, body, {
                headers: this.appendHeaders(accessToken)
            })
            .pipe(catchError(err => this.handelError(err)));
    }
    requestPostOutside(url: string, body: any, accessToken?: string) {
        return this.http
            .post(`${url}`, body, {
                headers: this.appendHeaders(accessToken)
            })
            .pipe(catchError(err => this.handelError(err)));
    }

    // ส่งข้อมูลแบบ Delete method
    requestDelete(url: string, accessToken?: string) {
        return this.http
            .delete(`${this.address}/${url}`, {
                headers: this.appendHeaders(accessToken)
            })
            .pipe(catchError(err => this.handelError(err)));
    }
    requestDeleteOutside(url: string, accessToken?: string) {
        return this.http
            .delete(`${url}`, {
                headers: this.appendHeaders(accessToken)
            })
            .pipe(catchError(err => this.handelError(err)));
    }

    // ส่งข้อมูลแบบ Put method
    requestPut(url: string, body: any, accessToken?: string) {
        return this.http
            .put(`${this.address}/${url}`, body, {
                headers: this.appendHeaders(accessToken)
            })
            .pipe(catchError(err => this.handelError(err)));
    }
    requestPutOutside(url: string, body: any, accessToken?: string) {
        return this.http
            .put(`${url}`, body, {
                headers: this.appendHeaders(accessToken)
            })
            .pipe(catchError(err => this.handelError(err)));
    }

    // ปรับแต่ง Error ใหม่
    private handelError(errResponse: HttpErrorResponse): Observable<any> {
        console.log(errResponse, "<-------orginal error");
        errResponse['Maessage'] = errResponse.message;
        errResponse['Color'] = this.statusColor(errResponse.status.toString());
        if (errResponse.error && errResponse.error.message)
            errResponse['Message'] = errResponse.error.message;
        throw errResponse;
    }

    // เพิ่ม header
    private appendHeaders(accessToken) {
        const headers = {};
        if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`;
        return new HttpHeaders(headers);
    }

    private statusColor(status_code) {
        status_code = status_code.substring(0, 1);
        let color = 'info';
        switch (status_code) {
            case '1': {
                color = 'info';
                break;
            }
            case '2': {
                color = 'success';
                break;
            }
            case '3': {
                color = 'danger';
                break;
            }
            case '4': {
                color = 'danger';
                break;
            }
            case '5': {
                color = 'danger';
                break;
            }
            default: {
                color = 'info';
                break;
            }
        }
        return color;
    }
}