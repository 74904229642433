<!-- Navbar -->
<nav class="main-header navbar navbar-expand navbar-info navbar-light head-font-2">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" href="#"><i class="fas fa-bars text-light"></i></a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
            <a [routerLink]="['/',AppURL.Authen,AuthURL.Starter]" class="nav-link text-light"><i class="fa fa-home mr-1 text-light" aria-hidden="true"></i>หน้าแรก</a>
        </li>
    </ul>

    <!-- SEARCH FORM -->
    <form class="form-inline ml-3 d-none d-md-block">
        <div class="input-group input-group-sm">
            <input class="form-control form-control-navbar" type="search" placeholder="ค้นหา" aria-label="Search">
            <div class="input-group-append">
                <button class="btn btn-navbar" type="submit">
                    <i class="fas fa-search"></i>
                </button>
            </div>
        </div>
    </form>
    <form class="form-inline ml-0 d-block d-md-none">
        <h4 class="head-font text-white mb-0">Quarantint T.8</h4>
    </form>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">

        <li class="nav-item">
            <a class="nav-link" data-widget="control-sidebar" data-slide="true" href="#"><i
                    class="fas fa-th-large text-light"></i></a>
        </li>
    </ul>
</nav>
<!-- /.navbar -->