<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-light-info elevation-4">
    <!-- Brand Logo -->
    <a href="index3.html" class="brand-link bg-moph-logo">
        <img src="assets/images/moph-logo.gif" alt="AdminLTE Logo" class="brand-image img-circle elevation-3">
        <!-- <div class="brand-image pt-1 text-info" style="font-size: 1.5em;">
            <i class="fas fa-user-check"></i>
        </div> -->
        <span class="brand-text font-weight-light text-light head-font">Quarantine T.8</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar head-font-5">
        <!-- Sidebar user panel (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
            <div class="image align-middle pt-2">
                <img src="assets/images/user.png" class="brand-image2 img-circle" alt="User Image">
            </div>
            <div class="info">
                <div class="row">
                    <div class="col-12">
                        <a [routerLink]="['/',AppURL.Authen,AuthURL.Profile]" class="d-block pointer">{{name}}
                            {{lastname}}</a>
                    </div>
                    <div class="col-12">
                        <a class="d-block pointer">{{imm}}</a>
                    </div>
                </div>

            </div>
        </div>

        <!-- Sidebar Menu -->
        <nav class="user-panel head-font">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-item">
                    <a [routerLink]="['/',AppURL.Authen,AuthURL.Profile]" class="nav-link" routerLinkActive="active">
                        <i class="fas fa-id-card-alt nav-icon"></i>
                        <p>ข้อมูลส่วนตัว</p>
                    </a>
                </li>
                <li class="nav-item">
                    <a (click)="onLogout()" class="nav-link pointer" routerLinkActive="active">
                        <i class="fas fa-sign-out-alt  nav-icon"></i>
                        <p>ออกจากระบบ</p>
                    </a>
                </li>
            </ul>
        </nav>
        <nav class="mt-2 head-font">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->

                <li class="nav-item" *ngxPermissionsOnly="page_roles.Starter">
                    <a [routerLink]="['/',AppURL.Authen,AuthURL.Starter]" routerLinkActive="active" class="nav-link">
                        <i class="fas fa-home nav-icon"></i>
                        <p>หน้าแรก</p>
                    </a>
                </li>
                <li class="nav-item" *ngxPermissionsOnly="page_roles.Statistic">
                    <a [routerLink]="['/',AppURL.Authen,AuthURL.Statistic]" routerLinkActive="active" class="nav-link">
                        <i class="fas fa-chart-bar nav-icon"></i>
                        <p>สถิติการบันทึกข้อมูล<span class="badge badge-danger ml-1">New</span></p>
                    </a>
                </li>
                <li class="nav-item" *ngxPermissionsOnly="page_roles.EnableUser">
                    <a [routerLink]="['/',AppURL.Authen,AuthURL.EnableUser]" routerLinkActive="active" class="nav-link">
                        <i class="fas fa-user-lock nav-icon"></i>
                        <p>อนุญาตให้ใช้งาน</p>
                    </a>
                </li>
                <li class="nav-item" *ngxPermissionsOnly="page_roles.GrantUserPermission">
                    <a [routerLink]="['/',AppURL.Authen,AuthURL.GrantUserPermission]" routerLinkActive="active" class="nav-link">
                        <i class="fas fa-user-lock nav-icon"></i>
                        <p>กำหนดสิทธิ์พื้นฐาน</p>
                    </a>
                </li>
                <li class="nav-item" *ngxPermissionsOnly="page_roles.ChangeUserRole">
                    <a [routerLink]="['/',AppURL.Authen,AuthURL.ChangeUserRole]" routerLinkActive="active"
                        class="nav-link">
                        <i class="fas fa-key nav-icon"></i>
                        <p>กำหนดสิทธิ์ผู้ดูแลระบบ</p>
                    </a>
                </li>
                <li class="nav-item" *ngxPermissionsOnly="page_roles.SetAdminLV1">
                    <a [routerLink]="['/',AppURL.Authen,AuthURL.SetAdminLV1]" routerLinkActive="active"
                        class="nav-link">
                        <i class="fas fa-user-shield nav-icon"></i>
                        <p>แต่งตั้ง Admin ขั้นต้น</p>
                    </a>
                </li>
                <li class="nav-item" *ngxPermissionsOnly="page_roles.AddFormT8">
                    <a [routerLink]="['/',AppURL.Authen,AuthURL.AddFormT8]" routerLinkActive="active" class="nav-link">
                        <i class="fab fa-wpforms nav-icon"></i>
                        <p>บันทึกแบบฟอร์ม ต.8</p>
                    </a>
                </li>
                <li class="nav-item" *ngxPermissionsOnly="page_roles.EditFormT8">
                    <a [routerLink]="['/',AppURL.Authen,AuthURL.EditFormT8]" routerLinkActive="active" class="nav-link">
                        <i class="fab fa-wpforms nav-icon"></i>
                        <p>รายการแบบฟอร์ม ต.8</p>
                    </a>
                </li>
                <li class="nav-item" *ngxPermissionsOnly="page_roles.ImmigrationExport">
                    <a [routerLink]="['/',AppURL.Authen,AuthURL.ImmigrationExport]" routerLinkActive="active"
                        class="nav-link">
                        <i class="fas fa-file-download nav-icon"></i>
                        <p>ดาวน์โหลด ต.8</p>
                    </a>
                </li>
                <li class="nav-item" *ngxPermissionsOnly="page_roles.AdminExport">
                    <a [routerLink]="['/',AppURL.Authen,AuthURL.AdminExport]" routerLinkActive="active"
                        class="nav-link">
                        <i class="fas fa-file-download nav-icon"></i>
                        <p>ดาวน์โหลด ต.8 (Admin)</p>
                    </a>
                </li>
                <li class="nav-item" *ngxPermissionsOnly="page_roles.AddFlight">
                    <a [routerLink]="['/',AppURL.Authen,AuthURL.AddFlight]" routerLinkActive="active" class="nav-link">
                        <i class="fas fa-plane nav-icon"></i>
                        <p>เพิ่มเที่ยวบิน</p>
                    </a>
                </li>
                <li class="nav-item" *ngxPermissionsOnly="page_roles.AddQuarantinePlace">
                    <a [routerLink]="['/',AppURL.Authen,AuthURL.AddQuarantinePlace]" routerLinkActive="active"
                        class="nav-link">
                        <i class="fas fa-home nav-icon"></i>
                        <p>เพิ่มสถานที่กักตัว</p>
                    </a>
                </li>
                <li class="nav-item" *ngxPermissionsOnly="page_roles.AdminImportMFA">
                    <a [routerLink]="['/',AppURL.Authen,AuthURL.AdminImportMFA]" routerLinkActive="active"
                        class="nav-link">
                        <i class="fas fa-file-import nav-icon"></i>
                        <p>นำเข้าข้อมูลจาก กต.</p>
                    </a>
                </li>
                <li class="nav-item" *ngxPermissionsOnly="page_roles.ProvincialExport">
                    <a [routerLink]="['/',AppURL.Authen,AuthURL.ProvincialExport]" routerLinkActive="active"
                        class="nav-link">
                        <i class="fas fa-file-download nav-icon"></i>
                        <p>ดาวน์โหลด ต.8 (สสจ.)</p>
                    </a>
                </li>
                <li class="nav-item" *ngxPermissionsOnly="page_roles.MophZoneExport">
                    <a [routerLink]="['/',AppURL.Authen,AuthURL.MophZoneExport]" routerLinkActive="active"
                        class="nav-link">
                        <i class="fas fa-file-download nav-icon"></i>
                        <p>ดาวน์โหลด ต.8 (เขตฯ)</p>
                    </a>
                </li>
                <li class="nav-item" *ngxPermissionsOnly="page_roles.OdpcZoneExport">
                    <a [routerLink]="['/',AppURL.Authen,AuthURL.OdpcZoneExport]" routerLinkActive="active"
                        class="nav-link">
                        <i class="fas fa-file-download nav-icon"></i>
                        <p>ดาวน์โหลด ต.8 (สคร.)</p>
                    </a>
                </li>


            </ul>
        </nav>
        <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
</aside>