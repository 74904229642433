import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable()
export class ValidatorsService {

    // สร้าง validate เอง โดยเช็ค รหัสผ่านและยืนยันรหัสผ่านให้เหมือนกัน
    comparePassword(passwordField: string) {
        return function (confirm_password: AbstractControl) {
            if (!confirm_password.parent) return;
            const password = confirm_password.parent.get(passwordField);
            const passwordSubscripe = password.valueChanges.subscribe(() => {
                confirm_password.updateValueAndValidity();
                passwordSubscripe.unsubscribe();
            });
            if (confirm_password.value === password.value)
                return;
            return { compare: true };
        }
    }


    // ตรวจสอบ password pattern เป็น A-z 0-9,8-16 ตัว,อักขระพิเศษ
    isPassword(password: AbstractControl) {
        if (password.value == '') return;
        if (/^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,16}$/.test(password.value)) return;
        return { password: true };
    }

    isCid(cid: AbstractControl) {
        if (cid.value == '') return;
        if (/^[0-9]{13}$/.test(cid.value)) return;
        return { cid: true };
    }

    isLaser(laser: AbstractControl) {
        if (laser.value == '') return;
        if (/^(?=.*[A-Z])(?=.*[0-9]).{12}$/.test(laser.value)) return;
        return { laser: true };
    }

    isEmail(email: AbstractControl) {
        if (email.value == '') return;
        if (/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(email.value)) return;
        return { email: true };
    }

    // ตัวอักษรภาษาอังกฤษหรือตัวเลข 4-16 ตัว
    isUserName(username: AbstractControl) {
        if (username.value == '') return;
        if (/^[A-Za-z0-9]{4,16}$/.test(username.value)) return;
        return { username: true };
    }

}