import { Injectable } from "@angular/core";
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';

declare const $: any;
@Injectable()
export class AlertService {

    constructor(
        private toastr: ToastrService,
    ){

    }
    notify(message: string, type: string = 'info') {
        switch (type) {
            case 'info':
                this.toastr.info(message, 'แจ้งเพื่อทราบ',{
                    positionClass:'toast-top-right'
                });
                break;
            case 'success':
                this.toastr.success(message, 'แจ้งเพื่อทราบ',{
                    positionClass:'toast-top-right'
                });
                break;
            case 'danger':
                this.toastr.error(message, 'เกิดความผิดพลาด',{
                    positionClass:'toast-top-right'
                });
                break;
            case 'warning':
                this.toastr.warning(message, 'คำเตือน',{
                    positionClass:'toast-top-right'
                });
                break;
        
            default:
                this.toastr.info(message, 'แจ้งเพื่อทราบ',{
                    positionClass:'toast-top-right'
                });
                break;
        }
        
    }

    confirm(message: string = 'คุณต้องการจะทำรายการต่อไปหรือไม่?'): Promise<any> {
        // return Swal.fire({
        //     title: 'ยืนยัน',
        //     text: message,
        //     type: 'warning',
        //     showCancelButton: true,
        //     confirmButtonText: 'ใช่',
        //     cancelButtonText: 'ไม่ใช่'
        // });
        return Swal.fire({
            title: 'ยืนยัน',
            text: message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText:'ไม่ใช่'
        });
    }
    success(message: string = 'ทำรายการสำเร็จ') {
        return Swal.fire('สำเร็จ', message, 'success')
    }
    error(message: string = 'ผิดพลาด') {
        return Swal.fire('ผิดพลาด', message, 'error')
    }


}