import { Injectable } from "@angular/core";
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
moment.locale('th');

@Injectable()
export class ShareService {
    constructor(
        private datePipe: DatePipe,
    ) {

    }
    // แปลงไฟล์รูปเป็น Base64
    onConvertImage(input: HTMLInputElement) {
        return new Promise((resolve, reject) => {
            const imageTypes = ['image/jpeg', 'image/png'];
            const imageSize = 300;
            // หากไม่มีการ อัพโหลดภาพ
            if (input.files.length == 0)
                return resolve(null);
            // ตรวจสอบชนิดไฟล์ที่อัพโหลดเข้ามา
            if (imageTypes.indexOf(input.files[0].type) < 0)
                return reject({ Message: 'กรุณาอัพโหลดรูปภาพเท่านั้น' });
            // ตรวจสอบขนาดของรูปภาพ
            if ((input.files[0].size / 1024) > imageSize)
                return reject({ Message: `กรุณาอัพโหลดภาพไม่เกิน ${imageSize} KB` });

            const reader = new FileReader();
            reader.readAsDataURL(input.files[0]);
            console.log("--------------------------", reader.result);
            // คืนค่า Image base64 ออกไป
            reader.addEventListener('load', () => resolve(reader.result));
        });
    }

    getThaiDate(date_str: string) {
        date_str = date_str.substring(0, 10);
        let date = new Date(date_str);
        let dt_pipe = this.datePipe.transform(date, 'yyyy-MM-dd');
        let dt_pipe_str: string[] = dt_pipe.split("-");
        let th_yr = Number.parseInt(dt_pipe_str[0]) + 543;

        let th = dt_pipe_str[2] + "-" + dt_pipe_str[1] + "-" + th_yr;
        return th;
    }

    idNoFormat(str: string) {
        let format = "";
        if (str != undefined && str != null && str != "" && str != "null") {
            format = str.substring(0, 1) + "-" + str.substring(1, 5) + "-" + str.substring(5, 10) + "-" + str.substring(10, 12) + "-" + str.substring(12, 13);
        } else {
            format = "-";
        }
        //x-xxxx-xxxxx-xx-x
        return format;
    }
    birthDayFormat(str: string) {
        let format = "";
        if (str != undefined && str != null && str != "" && str != "null") {
            format = str.substr(0, 4) + "-" + str.substr(4, 2) + "-" + str.substr(6, 2);
        } else {
            format = "-";
        }
        //x-xxxx-xxxxx-xx-x
        return format;
    }
    birthDayThai(str: string) {//25330130
        let format = "";
        if (str != undefined && str != null && str != "" && str != "null") {
            let dt = (Number.parseInt(str.substr(0, 4)) - 543) + "-" + str.substr(4, 2) + "-" + str.substr(6, 2);
            let dateString = moment(new Date(dt)).format('LLLL').split(" ");
            // format = dateString[0] + " " + dateString[1] + " " + dateString[2] + " " + (Number.parseInt(dateString[3]) + 543) + " " + dateString[4] + " " + dateString[5] + " น.";
            format = dateString[1] + " " + dateString[2] + " " + (Number.parseInt(dateString[3]) + 543);
        } else {
            format = "-";
        }
        //x-xxxx-xxxxx-xx-x
        return format;
    }

    ifNull(data: any) {
        if (data != undefined && data != "" && data != "null" && data != " ") {
            return data;
        } else {
            return "";
        }
    }
}