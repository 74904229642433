export const AuthURL = {
    Dashboard: "dashboard",
    AddImmigrationInfo: 'add-immigration-info',
    AddFormT8: "add-form-t8",
    EditFormT8: "edit-form-t8",
    Profile: "profile",
    EnableUser: "enable-user",
    ChangeUserRole: "change-user-role",
    SetAdminLV1: "set-admin-lv1",
    ImmigrationExport: "immigration-export",
    AdminExport: "admin-export",
    Starter: "starter",
    AdminImportMFA: "admin-import-mfa",
    AddFlight: "add-flight",
    AddQuarantinePlace: "add-quarantine-place",
    GrantUserPermission: "grant-user-permission",
    ProvincialExport: "provincial-export",
    MophZoneExport: "moph-zone-export",
    OdpcZoneExport: "odpc-zone-export",
    Statistic: "statistic",
}