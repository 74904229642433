import { Component, OnInit } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { PageRoles } from '../../services/page-role';
import { AlertService } from '../../services/alert.service';
import { Router } from '@angular/router';
import { AppURL } from '../../../app.url';
import { AuthURL } from '../../../authentication/authentication.url';
import { environment } from '../../../../environments/environment';
import * as jwt_decode from "jwt-decode";
import { AuthenService, TokenData } from '../../services/authen.service';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-auth-sidebar',
  templateUrl: './auth-sidebar.component.html',
  styleUrls: ['./auth-sidebar.component.scss']
})
export class AuthSidebarComponent implements OnInit {

  constructor(
    private permissionsService: NgxPermissionsService,
    private alert: AlertService,
    private router: Router,
    private authen: AuthenService,
    private userService: UsersService,
  ) {
    this.page_roles = PageRoles;
  }

  page_roles: any;
  myroles: string;
  AppURL = AppURL;
  AuthURL = AuthURL;
  root_main_path = environment.root_main_path;
  name: string = "";
  lastname: string = "";
  imm: string = "";
  ngOnInit(): void {
    // const perm = ["admin", "member", "guest"];
    let role = jwt_decode(this.authen.getAuthenticated()).roles;
    // console.log(role, role.length);
    this.permissionsService.loadPermissions(role);
    let user = this.authen.getUserFromToken();
    this.name = user.firstName;
    this.lastname = user.lastName;
    let rs = this.authen.getImmData();
    let place = rs['typeName'] + "" + rs['name'];
    place = (place.length > 20 ? place.substr(0, 20) + "..." : place);
    this.imm = place;
  }
  onLogout() {
    this.authen.clearAuthenticate();
    window.location.href = `${(this.root_main_path ? '/' : '')}${this.root_main_path}/th/login`;
  }

}
